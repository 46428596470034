/*screenLoader*/
.loader {
    width: 210px;
    height: 30px;
    position: relative;
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .loader, .typewriter {scale : .4};
  .typewriter{margin-top: 0;};
  .loader{ height: 36px;}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .loader, .typewriter {scale : .6};
  .typewriter{margin-top: 0;};
  .loader{ height: 36px;}
}


.loader .balls {
  filter: url(#goo);
}
.loader .balls .ball.moving {
  box-shadow: none;
}
.loader .ball {
  width: 30px;
  height: 30px;
  background-color: #4db9ff;
  position: absolute;
  border-radius: 30px;
  animation: 3s ease-in-out infinite;
}
.loader .ball:nth-child(1) {
  left: 0px;
  animation-name: scale1;
}
.loader .ball:nth-child(2) {
  left: 60px;
  animation-name: scale2;
}
.loader .ball:nth-child(3) {
  left: 120px;
  animation-name: scale3;
}
.loader .ball:nth-child(4) {
  left: 180px;
  animation-name: scale4;
}
.loader .ball.moving {
  animation: move 3s ease-in-out infinite;
  left: -15px;
  background-color: #50f2f5;
  box-shadow: 0 0 10px #50f2f5, 0 0 30px #50f2f5, 0 0 100px #50f2f5;
}

svg.splashLoader {
  display: none;
}

@keyframes move {
  50% {
    left: 200px;
  }
}
@keyframes scale1 {
  0%, 100% {
    transform: scale(1.1);
  }
  10%, 90% {
    transform: scale(1.2);
  }
  20%, 80% {
    transform: scale(1);
  }
}
@keyframes scale2 {
  20%, 80% {
    transform: scale(1.2);
  }
  10%, 30%, 70%, 90% {
    transform: scale(1);
  }
}
@keyframes scale3 {
  30%, 70% {
    transform: scale(1.2);
  }
  20%, 40%, 60%, 80% {
    transform: scale(1);
  }
}
@keyframes scale4 {
  50% {
    transform: scale(1.1);
  }
  40%, 60% {
    transform: scale(1.2);
  }
  30%, 70% {
    transform: scale(1);
  }
}
.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(161, 195, 223); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: 
    typing 2s steps(40, end)  infinite,
    blink-caret .5s step-end infinite;
  margin-top: 30px;
  color: rgb(161, 195, 223);
  font-weight: 400;
}

/* The typing effect */
@keyframes typing {
  0% { width: 0 }
  30% {width : 0}
  100% { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  0%, 100% { border-color: transparent }
  50% { border-color: rgb(161, 195, 223); }
}